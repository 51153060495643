import project from '../../public/imgs/steps/project.png'
import analitic from '../../public/imgs/steps/analitic.png'
import finish from '../../public/imgs/steps/finish.png'

export const STEPS_INFO = [
    {
        id: 0, 
        header: 'Предпроектная подготовка',
        image: project.src,
        slogan: 'Этот этап подразумевает под собой различное совместное взаимодействие с вами: опросы, выяснение важных аспектов для успешного выполнения проекта.',
        list: [
            {
                id: 0,
                title: 'Брифинг',
                text: 'Обсуждаем какой результат необходимо получить. Команда получает представление о проекте, погружается в тему проекта. Выясняем основную цель сайта и задачи, которые он должен выполнять.'
            },
            {
                id: 1,
                title: 'Отрисовка первичной версии прототипа',
                text: `Да, перед утверждением ТЗ мы рисуем прототип (при необходимости). Чтобы осознать, правильно ли мы поняли друг друга уже перед разработкой. Вы уже имеете готовый шаблон своего сайта и видите каким он получится в итоге.\n
                Так проще понять этого ли вы хотите на самом деле. В процессе можно отредактировать любой блок и любую страницу, чтобы вам все понравилось.`
            },
            {
                id: 2,
                title: 'Составление ТЗ, подписание договора',
                text: 'Утверждаем техническое задание по прототипу. Делаем смету проекта - рассчитываем предположительное количество времени, которое будет затрачено под разработку, рассчитываем стоимость. Подписываем договор.'
            },
        ]
    },
    {
        id: 1,
        header: 'Аналитика и разработка',
        image: analitic.src,
        slogan: 'На этом этапе мы погружаемся в вашу деятельность. Изучаем нишу бизнеса, анализируем конкурентов и смотрим как можно выделиться, в лучшем исполнении. Составляем портреты целевой аудитории, изучаем паттерны их поведения и факторы принятия решений чтобы получить максимально подходящее для них решение.',
        list: [
            {
                id: 0,
                title: 'Создаем дизайн',
                text: 'На основе ваших пожеланий, вашего вкуса, видения проекта, а также на основе потребностей ЦА и нашем опыте разрабатываем дизайн сайта. Который поможет продать продукт и будет правильно позиционировать вашу компанию.'
            },
            {
                id: 1,
                title: 'Разработка',
                text: 'Разрабатываем клиентскую часть (фронтенд) - чтобы обеспечить работу интерфейса и взаимодействие его с пользователем. И серверную (бэкенд) - отвечающую за обработку запросов пользователя и хранение данных. Эти два стека работают вместе, чтобы создать красивое и функциональное приложение, которое будет работать быстро и эффективно.'
            },
            {
                id: 2,
                title: 'Система управления сайтом и тестирование.',
                text: 'Подключаем админ-панель, создаем аккаунты с необходимыми уровнями доступа. Проверяем функциональность сайта и убеждаемся в том, что он работает корректно и соответствует вашим требованиям.'
            }
        ]
    },
    {
        id: 2,
        header: 'Сдача проекта',
        image: finish.src,
        slogan: 'Загружаем сайт на хостинг и передаем управление вам.',
        list: [
            {
                id: 0,
                title: 'Поддержка',
                text: 'Мы всегда рады продолжать сотрудничество и оказываем поддержку сайта на основе наших тарифов. Она включает в себя регулярное обновление контента, исправление ошибок и уязвимостей. Поддержка сайта может помочь улучшить его поисковую оптимизацию и привлечение трафика, что может привести к увеличению посещаемости и доходов.'
            },
        ]
    }
]
